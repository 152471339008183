.container-footer{
    background-color: var(--wine);
    padding: 50px 0;
}
.footer_logo_numbers{
    display: flex;
    justify-content: space-between;
}
.footer_logo{
    display: flex;
    margin-left: 250px;
}
.footer_logo img{
    align-self: center;
    height: auto;
    width: 190px;
}
.footer_numbers{
    display: flex;
}
.footer_numbers div{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}
.footer_numbers p{
    color: white;
}
.footer_contacto h6 a, .footer_contacto h6, .footer_contacto p{
    text-decoration: none;
    color: white;
}
.container-footer .second-row p{
    color: white;
    padding-top: 10px;
}
.footer_sociales{
    display: flex;
    justify-content: center;
    
}
.footer_sociales a{
    color: white;
    font-size: 70px;
    margin: 0 40px;
}
@media (max-width: 176px) {
    .footer_logo_numbers{
        flex-direction: column;
    }
    .footer_numbers{
        flex-direction: column;
    }
    .footer_logo {
        justify-content: center;
        margin: 0;
    }
    .footer_logo img {
        width: 150px;
    }
}